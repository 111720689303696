<template>
	<section>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Crear nuevo cliente</div>
			</div>
			<div class="card-body">
				<form @submit.prevent="enviar()">
					<!-- Fila 1 -->
					<div class="columns">
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Nombre completo</label>
							<input class="form-input" v-model="form.datos.nombre" type="text" required>
						</div>
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Cédula</label>
							<input class="form-input" v-model="form.datos.cedula" type="text" required>
						</div>
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Teléfono</label>
							<input class="form-input" v-model="form.datos.telefono" type="text" required>
						</div>
						<div class="form-group column col-sm-12 col-8">
							<label class="form-label">Dirección</label>
							<textarea class="form-input" v-model="form.datos.direccion" rows="3"></textarea>
						</div>
					</div>

					<div class="divider"></div>
					<div class="form-group">
						<button type="submit" class="btn btn-primary" :class="form.cargando ? 'loading' : ''">Crear Cliente</button>
					</div>
				</form>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Listado de Clientes</div>
			</div>
			<div v-if="tabla.cargando" class="card-body">
				<div class="loading loading-lg"></div>
			</div>
			<div v-else class="card-body">
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Cédula</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(i, k) of tabla.datos" :key="k" @click="irCliente(i.id)" class="c-hand">
							<td>{{ i.nombre }}</td>
							<td>{{ i.cedula }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'SV_Clientes',
		data() {
			return {
				form: {
					cargando: false,
					datos: {}
				},
				tabla: {
					cargando: false,
					datos: []
				}
			}
		},
		mounted() {
			document.title = 'Clientes';
			this.infoUsuario();
			this.listadoTabla();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			enviar() {
				this.form.cargando = true;
				this.$store.state.api
					.post('clientes/crear', this.form.datos)
					.then(() => {
						this.form.datos = {
							nombre: '',
							cedula: '',
							telefono: '',
							direccion: ''
						};
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => {
						this.form.cargando = false;
						this.listadoTabla();
					});
			},
			listadoTabla() {
				this.tabla.cargando = true;
				this.$store.state.api
					.get('clientes/listado')
					.then(res => this.tabla.datos = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.tabla.cargando = false);
			},
			irCliente(id) {
				this.$router.push({
					name: 'Cliente',
					params:{ id: id }
				});
			}
		}
	}
</script>
